<template>
  <div v-if="showTab" class="l-main">
    <div class="l-main__header u-box">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a class="c-breadcrumbs__link" @click="goToCompanies">
            <md-icon>home</md-icon>
          </a>
        </li>
        <li class="c-breadcrumbs__item" @click="goToCompanies">
          <a class="c-breadcrumbs__link">Companies</a>
        </li>
        <li class="c-breadcrumbs__item" @click="goToMarket">
          <a class="c-breadcrumbs__link">{{ companyName }}</a>
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link">{{ marketName }}</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <md-tabs class="c-tabs c-tabs--large" :md-active-tab="selectedTab">
        <md-tab
          v-for="tab in availableTabs"
          :id="tab.tab"
          :key="tab.tab"
          :md-label="tab.label"
          @click="changeActiveTab(tab.tab)"
        />
      </md-tabs>
      <component
        :is="currentTab"
        v-if="availableTabs.length"
        :api-configurations-data="getApiConfigurationsData"
        :api-selected-configurations-data="getApiMarketConfigurationsData"
        :type-id="marketId"
        :type="typeName"
        @canChangeTab="canChangeTab"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { allBetshopsTabs } from '../../const';
import parseInt from 'lodash/parseInt';
import get from 'lodash/get';
import each from 'lodash/each';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep'
import GenericButton from '../../shared/buttons/GenericButton';
import AllBetshops from '../betshops/all-betshops';
import PageConfigurations from './components/page-configurations';
import TicketDesciptions from './components/ticket-descriptions';
import TicketSettings from './components/ticket-settings';
import AdditionalTemplateSettings from './components/additional-template-settings';
import ReceiptSettings from './components/receipt-settings';
import ShiftSettings from './components/shift-settings';
import DisabledPages from "../betshops/components/disabled-pages";
import { isTabOrSubTabAvailable } from '../../helpers/permission';
import ConfirmationModalComponent from '../../shared/confirmation-modal/ConfirmationModalComponent';

export default {
  components: {
    GenericButton,
    PageConfigurations,
    AllBetshops,
    TicketDesciptions,
    TicketSettings,
    ShiftSettings,
    ReceiptSettings,
    DisabledPages,
    AdditionalTemplateSettings
  },
  data() {
    return {
      currentTab: 'all-betshops',
      marketId: null,
      typeName: 'market',
      availableTabs: [],
      selectedTab: 'all-betshops',
      previousTab: 'all-betshops',
      showTab: false,
      initApiConfigurations: {},
      canOpenModal: false
    };
  },
    
  computed: {
    ...mapState('company', ['companyName', 'marketName', 'errorFromRest']),
    ...mapGetters('company', ['getApiConfigurationsData', 'getApiMarketConfigurationsData']),

    allBetshopsTabs() {
      return allBetshopsTabs;
    },
  },

  async created() {
    await this.getMarketBredCrumps(this.$route.params.marketId);
    await this.getConfigurationsAndMarketConfigurations();
    await this.getMarketLanguages({companyId: this.$route.params.companyId, marketId: this.$route.params.marketId});
    this.setAvailableTabs();
    this.initApiConfigurations = cloneDeep(this.getApiMarketConfigurationsData);
  },

  beforeRouteLeave(to, from, next) {
    if (this.checkPageFormChanges()) {
      this.openConfirmationModal(() => { 
        if (to.name !== 'markets') {
          this.SET_COMPANY_NAME(null);
        }
        this.SET_MARKET_NAME(null); 
        next(); 
      });
      return;
    }
    if (to.name !== 'markets') {
      this.SET_COMPANY_NAME(null);
    }
    this.SET_MARKET_NAME(null);
    next();
  },

  methods: {
    ...mapActions('company', ['getMarketBredCrumps', 'fetchConfigurationsAndMarketConfigurations', 'getMarketLanguages']),
    ...mapMutations('company', ['SET_COMPANY_NAME', 'SET_MARKET_NAME']),
    ...mapGetters('account', ['user', 'userHierarchyAllowedTabs']),

    async getConfigurationsAndMarketConfigurations() {
      const marketId = parseInt(get(this.$route, 'params.marketId'));
      this.marketId = marketId;
      await this.fetchConfigurationsAndMarketConfigurations(marketId)
    },
    
    openConfirmationModal(onAccept) {
      this.$modal.show(
          ConfirmationModalComponent,
          {
            confirmationMsg: `
              Your changes will be lost,
              are you sure you want to continue?`,
            onAccept: onAccept,
            onClose: () => {
              setTimeout(() => {
                this.selectedTab = this.previousTab
              });
            },
          },
          { height: "auto", scrollable: false, width: "400px" }
        );
    },

    checkPageFormChanges() {
      return !isEqual(this.initApiConfigurations, this.getApiMarketConfigurationsData) || this.canOpenModal;
    },
    goToCompanies() {
      this.$router.push({ name: 'companies' });
    },

    goToMarket() {
      this.$router.push({
        name: 'markets',
        params: { marketId: this.$route.params.companyId },
      });
    },

    changeActiveTab(tab) {
      this.selectedTab = tab;
      if (this.canOpenModal) {
        this.openConfirmationModal(() => { this.previousTab = this.tab; this.currentTab = tab; this.canOpenModal = false; });
        return;
      }
      this.currentTab = tab;
      this.previousTab = tab;
    },

    canChangeTab(canOpenModal) {
      if (!canOpenModal) {
        this.initApiConfigurations = cloneDeep(this.getApiMarketConfigurationsData);
      }
      this.canOpenModal = canOpenModal;
    },

    setAvailableTabs() {
      if (this.user().role === 1) {
        this.availableTabs = [...this.allBetshopsTabs];
        this.setDefaultTab();
        
        return;
      }

      each(this.allBetshopsTabs, tab => {
        if (!isTabOrSubTabAvailable(tab, this.userHierarchyAllowedTabs(), '-market')) {
          return;
        }

        this.availableTabs.push(tab);
      })

      this.setDefaultTab();
    },

    setDefaultTab() {
      if (this.availableTabs.length === 0) {
        return this.showTab = true;
      }
      
      const firstAvailableTab = this.availableTabs[0].tab

      this.currentTab = firstAvailableTab;
      this.selectedTab = firstAvailableTab;
      this.previousTab = firstAvailableTab;
      this.showTab = true;
    }
  },
};
</script>
