import Vue from 'vue'
import Vuex from 'vuex'
import { account } from './modules/account';
import { response } from './modules/response';
import { company } from './modules/company';
import { theme } from './modules/theme';
import { shift } from './modules/shift';
import { receipt } from './modules/receipt';
import { ticket } from './modules/ticket';
import { user } from './modules/user';
import { hqSettings } from './modules/hq-settings';
import { additionalTemplate } from './modules/additional-template';

Vue.use(Vuex)

const state = {}
const mutations = {}
const actions = {}
const getters = {}

export const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    account,
    response,
    company,
    theme,
    shift,
    receipt,
    user,
    ticket,
    hqSettings,
    additionalTemplate,
  }
})
