export const betshopTabs = [
  {
    tab: 'general-settings',
    label: 'General settings',
    section: 'settings',
    subItems: [],
  },
  {
    tab: 'licence-keys',
    label: 'Licence keys',
    section: 'licenceKeys',
    subItems: [],
  },
  {
    tab: 'ticket-settings',
    label: "Ticket Settings",
    section: 'ticketSettings',
    subItems: [
      {
        tab: 'page-configurations',
        label: 'Settings',
        subSection: 'print-settings',
        subItems: [],
      },
      {
        tab: 'ticket-descriptions',
        label: 'Settings',
        subSection: 'ticket-descriptions',
        subItems: [],
      },
      {
        tab: 'ticket-top-section',
        label: 'Settings',
        subSection: 'ticket-top-section',
        subItems: [],
      }
    ],
  },
  {
    tab: 'shift-settings',
    label: "Shift Settings",
    section: 'shiftSettings',
    subItems: [],
  },
  {
    tab: 'additional-template-settings',
    label: "Additional Template",
    section: 'additionalTemplates',
    subItems: [
      {
        tab: 'additional-template-print-settings',
        label: 'Settings',
        subSection: 'additional-template-print-settings',
        subItems: [],
      },
      {
        tab: 'additional-template-footer',
        label: 'Settings',
        subSection: 'additional-template-footer',
        subItems: [],
      },
    ],
  },
  {
    tab: 'receipt-settings',
    label: "Receipt Settings",
    section: 'receiptSettings',
    subItems: [],
  },
  {
    tab: 'disabled-pages',
    label: 'Disabled Pages',
    section: 'disabledPages',
    subItems: [],
  },
];

export const allBetshopsTabs = [
  {
    tab: 'all-betshops',
    label: 'Betshops',
    section: 'betShops',
    subItems: [],
  },
  {
    tab: 'page-configurations',
    label: 'Settings',
    section: 'settings',
    subItems: [],
  },
  {
    tab: 'ticket-settings',
    label: "Ticket Settings",
    section: 'ticketSettings',
    subItems: [
      {
        tab: 'page-configurations',
        label: 'Settings',
        subSection: 'print-settings',
        subItems: [],
      },
      {
        tab: 'ticket-descriptions',
        label: 'Settings',
        subSection: 'ticket-descriptions',
        subItems: [],
      },
      {
        tab: 'ticket-top-section',
        label: 'Settings',
        subSection: 'ticket-top-section',
        subItems: [],
      }
    ],
  },
  {
    tab: 'shift-settings',
    label: "Shift Settings",
    section: 'shiftSettings',
    subItems: [],
  },
  {
    tab: 'additional-template-settings',
    label: "Additional Template",
    section: 'additionalTemplates',
    subItems: [
      {
        tab: 'additional-template-print-settings',
        label: 'Settings',
        subSection: 'additional-template-print-settings',
        subItems: [],
      },
      {
        tab: 'additional-template-footer',
        label: 'Settings',
        subSection: 'additional-template-footer',
        subItems: [],
      },
    ],
  },
  {
    tab: 'receipt-settings',
    label: 'Receipt Settings',
    section: 'receiptSettings',
    subItems: [],
  },
  {
    tab: 'disabled-pages',
    label: 'Disabled Pages',
    section: 'disabledPages',
    subItems: [],
  },
];

export const manageAppTabs = [
  {
    tab: 'drivers',
    label: 'Drivers',
  }
];

export const managePrintersTabs = [
  {
    tab: 'manage-printers',
    label: 'Printers',
  }
];

export const ticketSettings = [
  {
    tab: 'print-ticket-settings',
    label: 'Print Settings',
    subSection: 'print-settings'
  },
  {
    tab: 'ticket-desciptions',
    label: 'Ticket Descriptions',
    subSection: 'ticket-descriptions'
  },
  {
    tab: 'ticket-top-section',
    label: 'Ticket Top Section',
    subSection: 'ticket-top-section'
  }
];

export const additionalTemplateTabs = [
  {
    tab: 'additional-template-print-settings',
    label: 'Additional Print Settings',
    subSection: 'additional-template-print-settings'
  },
  {
    tab: 'additional-template-footer',
    label: 'Additional Template Footer',
    subSection: 'additional-template-footer'
  },
];

export const shiftSettings = [
  {
    tab: 'open-shift-template-configuration',
    label: 'Open Shift Configuration'
  },
  {
    tab: 'close-shift-template-configuration',
    label: 'Close Shift Configuration',
  }
];

export const receiptSettings = [
  {
    tab: 'deposit-receipt-template-configuration',
    label: 'Deposit Receipt Configuration'
  },
  {
    tab: 'withdraw-receipt-template-configuration',
    label: 'Withdraw Receipt Configuration',
  },
];

export const shiftKeysTabs = [
  {
    tab: 'open-shift-keys',
    label: 'Open shift keys',
  },
  {
    tab: 'close-shift-keys',
    label: 'Close shift keys',
  }
];

export const receiptKeysTabs = [
  {
    tab: 'deposit-receipt-keys',
    label: 'Deposit Receipt keys',
  },
  {
    tab: 'withdraw-receipt-keys',
    label: 'Withdraw Receipt keys',
  }
];

export const permissions = [
  { name: 'companies' },
  { name: 'templates' },
  { name: 'translations' },
  { name: 'configurations'}
];

export const logoFileExtensions = ['png', 'jpg', 'jpeg'];

export const templatesTabs = [
  {
    tab: 'ticket-template',
    label: 'Ticket Template'
  },
  {
    tab: 'open-shift-template',
    label: 'Open Shift Template'
  },
  {
    tab: 'close-shift-template',
    label: 'Close Shift Template'
  },
  {
    tab: 'additional-template',
    label: 'Additional Template'
  },
]

export const shiftTypes = {
  openShift: 'open',
  closeShift: 'close',
}

export const receiptTypes = {
  depositReceipt: 'deposit',
  withdrawReceipt: 'withdraw',
}

export const userEditTabs = [
  {
    tab: 'hierarchy-permission',
    label: 'Hierarchy'
  },
  {
    tab: 'wsbo-permission',
    label: 'Wsbo'
  }
]
