export const wsboPermission = {
  "companies": {
    permissions: [
      {
        key: 'companies',
        page: 'companies',
        pageTab: null,
        read: false,
        write: false,
      },
    ],
  },
  "templates": {
    permissions: [
      {
        key: 'ticket-template',
        page: null,
        pageTab: 'ticket-template',
        read: false,
        write: false,
      },
      {
        key: 'open-shift-template',
        page: null,
        pageTab: 'open-shift-template',
        read: false,
        write: false,
      },
      {
        key: 'close-shift-template',
        page: null,
        pageTab: 'close-shift-template',
        read: false,
        write: false,
      },
      {
        key: 'additional-template',
        page: null,
        pageTab: 'additional-template',
        read: false,
        write: false,
      },
    ],
  },
  "translations": {
    permissions: [
      {
        key: 'all-translations',
        page: 'all-translations',
        pageTab: null,
        read: false,
        write: false,
        subItem: true,
      },
      {
        key: 'sections',
        page: 'sections',
        pageTab: null,
        read: false,
        write: false,
        subItem: true,
      },
      {
        key: 'languages',
        page: 'languages',
        pageTab: null,
        read: false,
        write: false,
        subItem: true,
      },
    ],
  },
  "configurations": {
    permissions: [
      {
        key: 'pages',
        page: 'pages',
        pageTab: null,
        read: false,
        write: false,
        subItem: true,
      },
      {
        key: 'open-shift-keys',
        page: 'shift-keys',
        pageTab: 'open-shift-keys',
        read: false,
        write: false,
        subItem: true,
      },
      {
        key: 'close-shift-keys',
        page: 'shift-keys',
        pageTab: 'close-shift-keys',
        read: false,
        write: false,
        subItem: true,
      },
      {
        key: 'deposit-receipt-keys',
        page: 'receipt-keys',
        pageTab: 'deposit-receipt-keys',
        read: false,
        write: false,
        subItem: true,
      },
      {
        key: 'withdraw-receipt-keys',
        page: 'receipt-keys',
        pageTab: 'withdraw-receipt-keys',
        read: false,
        write: false,
        subItem: true,
      },
      {
        key: 'ticket-keys',
        page: 'ticket-keys',
        pageTab: null,
        read: false,
        write: false,
        subItem: true,
      },
      {
        key: 'hq-settings',
        page: 'hq-settings',
        pageTab: null,
        read: false,
        write: false,
        subItem: true,
      },
      {
        key: 'additional-template-keys',
        page: 'additional-template-keys',
        pageTab: null,
        read: false,
        write: false,
        subItem: true,
      },
    ],
  },
  "manage-drivers": {
    permissions: [
      {
        key: 'drivers',
        page: null,
        pageTab: 'drivers',
        read: false,
        write: false,
      },
    ],
  },
  "manage-printers": {
    permissions: [
      {
        key: 'manage-printers',
        page: null,
        pageTab: 'manage-printers',
        read: false,
        write: false,
      },
    ]
  },
  "betshop-versions": {
    permissions: [
      {
        key: 'betshop-versions',
        page: null,
        pageTab: 'betshop-versions',
        read: false,
        write: false,
      },
    ],
  },
  "manage-versions": {
    permissions: [
      {
        key: 'manage-versions',
        page: null,
        pageTab: 'manage-versions',
        read: false,
        write: false,
      }
    ]
  },

};

export const hierarchyPermission = {
  "companyIds": [],
  "marketIds": [],
  "betshopIds": [],
  "hierarchy": {
    "betShops": {
      isVisible: false,
      market: true,
      betshop: false,
      permissions: [
        {
          page: 'betShops',
          pageTab: null,
          marketRead: false,
          marketWrite: false,
        },
      ],
    },
    "settings": {
      isVisible: false,
      market: true,
      betshop: true,
      permissions: [
        {
          page: 'settings',
          pageTab: null,
          betshopRead: false,
          betshopWrite: false,
          marketRead: false,
          marketWrite: false,
        },
      ],
    },
    "ticketSettings": {
      isVisible: false,
      market: true,
      betshop: true,
      permissions: [
        {
          page: 'ticketSettings',
          pageTab: 'print-settings',
          betshopRead: false,
          betshopWrite: false,
          marketRead: false,
          marketWrite: false,
        },
        {
          page: 'ticketSettings',
          pageTab: 'ticket-descriptions',
          betshopRead: false,
          betshopWrite: false,
          marketRead: false,
          marketWrite: false,
        },
        {
          page: 'ticketSettings',
          pageTab: 'ticket-top-section',
          betshopRead: false,
          betshopWrite: false,
          marketRead: false,
          marketWrite: false,
        },
      ],
    },
    "shiftSettings": {
      isVisible: false,
      market: true,
      betshop: true,
      permissions: [
        {
          page: 'shiftSettings',
          pageTab: null,
          betshopRead: false,
          betshopWrite: false,
          marketRead: false,
          marketWrite: false,
        }
      ],
    },
    "receiptSettings":{
      isVisible: false,
      market: true,
      betshop: true,
      permissions: [
        {
          page: 'receiptSettings',
          pageTab: null,
          betshopRead: false,
          betshopWrite: false,
          marketRead: false,
          marketWrite: false,
        }
      ],
    },
    "disabledPages": {
      isVisible: false,
      market: true,
      betshop: true,
      permissions: [
        {
          page: 'disabledPages',
          pageTab: null,
          betshopRead: false,
          betshopWrite: false,
          marketRead: false,
          marketWrite: false,
        },
      ],
    },
    "additionalTemplates": {
      isVisible: false,
      market: true,
      betshop: true,
      permissions: [
        {
          page: 'additionalTemplates',
          pageTab: 'additional-template-print-settings',
          betshopRead: false,
          betshopWrite: false,
          marketRead: false,
          marketWrite: false,
        },
        {
          page: 'additionalTemplates',
          pageTab: 'additional-template-footer',
          betshopRead: false,
          betshopWrite: false,
          marketRead: false,
          marketWrite: false,
        },
      ],
    },
    "licenceKeys": {
      isVisible: false,
      market: false,
      betshop: true,
      permissions: [
        {
          page: 'licenceKeys',
          pageTab: null,
          betshopRead: false,
          betshopWrite: false
        },
      ],
    },
  }
};
