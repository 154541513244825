import { apiService } from '../api';
import { replacePathParams } from '../../helpers/urlHelpers';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

const ENDPOINTS = {
  GET_CONFIGURATIONS: '/configurations',
  GET_MARKET_CONFIGURATIONS: '/market/:marketId/configurations',
  GET_BET_SHOP_CONFIGURATIONS: '/betshop/:betshopId/configurations',
  UPDATE_MARKET_CONFIGURATIONS: '/markets/:marketId/configuration/:configurationId',
  UPDATE_BET_SHOP_CONFIGURATIONS: '/betshops/:betshopId/configuration/:configurationId'
}

class ConfigurationsService {
  async getConfigurations() {
    return await apiService.get(ENDPOINTS.GET_CONFIGURATIONS)
  }

  async getByMarketId(marketId) {
    return await apiService.get(replacePathParams(ENDPOINTS.GET_MARKET_CONFIGURATIONS, { marketId }))
  }

  async updateMarketConfigurations(marketId, configurationId, value) {
    return await apiService.post(
      replacePathParams(ENDPOINTS.UPDATE_MARKET_CONFIGURATIONS, { marketId, configurationId }),
      value
    )
  }

  async updateMultipleMarketConfigurations(marketId, data) {
    return await apiService.put(
      replacePathParams(ENDPOINTS.GET_MARKET_CONFIGURATIONS, { marketId }),
      data
    )
  }

  async getByBetShopId(betshopId) {
    return await apiService.get(replacePathParams(ENDPOINTS.GET_BET_SHOP_CONFIGURATIONS, { betshopId }))
  }

  async updateBetShopConfigurations(betshopId, configurationId, value) {
    return await apiService.post(
      replacePathParams(ENDPOINTS.UPDATE_BET_SHOP_CONFIGURATIONS, { betshopId, configurationId }),
      value
    )
  }

  async updateMultipleBetshopConfigurations(betshopId, data) {
    return await apiService.put(
      replacePathParams(ENDPOINTS.GET_BET_SHOP_CONFIGURATIONS, { betshopId }),
      data
    )
  }

  parseApiConfig(data) {
    return map(data, (config) => ({
      ...config,
      defaultValue: get(config, 'defaultValue') && JSON.parse(get(config, 'defaultValue')),
      values: get(config, 'values') && JSON.parse(get(config, 'values'))
    }));
  }

  parseInstanceConfigIfNoConfigAdded(apiConfigData, instanceIdProperty, instanceId) {
    return map(apiConfigData, (config) => ({
        [instanceIdProperty]: instanceId,
        configurationId: get(config, 'id'),
        key: get(config, 'key'),
        value: get(config, 'defaultValue'),
      }));
  }

  parseInstanceConfigIfSomeConfigAdded(apiConfigData, instanceConfigData, instanceIdProperty, instanceId) {
    return map(apiConfigData, (config) => {
      const key = get(config, 'key');
      const instanceConfig = find(instanceConfigData, instanceConfig => get(instanceConfig, 'key') === key);
      if (instanceConfig) {
        instanceConfig.value = JSON.parse(get(instanceConfig, 'value'));
      }

      return {
        [instanceIdProperty]: instanceId,
        configurationId: get(config, 'id'),
        key,
        value: get(config, 'defaultValue'),
        ...instanceConfig,
      }
    });
  }

  parseInstanceConfig(data, confData = []) {
    if (confData && !isEmpty(confData) && data.length < confData.length) {
      data.push({value: ''})
    }
    
    return sortBy(
      map(data, (config) => ({
        ...config,
        value: get(config, 'value') && JSON.parse(get(config, 'value'))
      })),
      (config) => config.configurationId
    );
  }

  prepareConfigurations(type, apiConfigurationsData, typeId, data) {
    if (!get(data, 'length')) {
      return this.parseInstanceConfigIfNoConfigAdded(
        apiConfigurationsData,
        type,
        typeId
      );
    }

    if (get(data, 'length') < get(apiConfigurationsData, 'length')) {
      return this.parseInstanceConfigIfSomeConfigAdded(
        apiConfigurationsData,
        data,
        type,
        typeId
      );
    } 
    return this.parseInstanceConfig(data);
  }
}

export const configurationsService = new ConfigurationsService()
